<template>
  <v-menu
    v-model="showMenu"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    class="wx-date-picker-menu"
    width="100%"
    max-width="290px"
    offset-y
  >
    <template v-slot:activator="{ on }">
      <wx-text-field
        :label="label"
        :value="computedDate"
        :rules="rules"
        :title="$t('datePicker.fieldHoverTitle')"
        :class="[setDateFieldClass()]"
        class="date-text-field"
        prepend-icon="$calendarItemIcon"
        v-on="on"
        readonly
      />
    </template>
    <!-- 
      IMPORTANT
      If prop `range` is true make sure it’s value type is Array `[]`
      -->
    <v-date-picker
      v-model="date"
      @input="emitDate()"
      :range="range"
      :min="minDate"
      header-color="primary"
      color="primary"
      light
      :locale="datePickerLocale"
    />
  </v-menu>
</template>

<script>
import WxTextField from "@/components/ui/WxTextField";

export default {
  name: "WxDatePicker",
  components: {
    WxTextField,
  },
  props: {
    value: {
      type: [String, Array],
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    rules: {
      type: Array,
      required: true,
    },
    dateFieldClass: {
      type: String,
      default: "mt-3",
    },
    range: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showMenu: false,
      date: this.value,
      minDate: "2019-09-01",
    };
  },
  watch: {
    value() {
      this.date = this.value;
    },
  },
  computed: {
    computedDate() {
      return this.date;
    },
    datePickerLocale() {
      switch (this.$i18n.locale) {
        case "fr":
          return "fr-CA";
        case "es":
          return "es-US";
        default:
          return "en-US";
      }
    },

  },
  methods: {
    emitDate() {
      if (this.range) {
        this.showMenu = this.date.length !== 2;
        this.$emit("input", this.getSortedRangeDates(this.date));
      } else {
        this.showMenu = false;
        this.$emit("input", this.date);
      }
    },
    setDateFieldClass() {
      return this.dateFieldClass;
    },
    getSortedRangeDates(dates) {
      if (dates.length < 2) return dates;

      const date1 = Date.parse(dates[0]);
      const date2 = Date.parse(dates[1]);

      if (date1 <= date2) return dates;
      // else print higher date value last
      return [dates[1], dates[0]];
    },
    clickSubmitRangeAction() {
      return this.clickSubmitRange;
    },
  },
};
</script>

<style>
.date-text-field {
  min-width: 8.5rem;
  max-width: 12rem;
}
</style>
